import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Nav from '../components/NavBarContainer';
import Footer from '../components/Footer';
import Slider from "react-slick";
import '../App.css';
import AudioPlayer from 'react-h5-audio-player';
import 'react-h5-audio-player/lib/styles.css';
import { TypeAnimation } from 'react-type-animation';

const Home = () => {

  let settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  return(
  <div>
   <div id="body">
    <Nav />
   <section id="header">
   <div>
   <h3 className="text-uppercase text-center">One Word From God</h3>
   <h1 className="text-capitalize text-center text-light" style={{ marginTop: -18 }}>
   <TypeAnimation
   preRenderFirstString={true}
   cursor={false}
   sequence={[
    // Same substring at the start will only be typed out once, initially
    1000, // wait 1s before replacing "Mice" with "Hamsters"
    'Can Change Your Life',
    1000,
    'Can Change Your Situation',
    1000,
    'Can Turn Your Life Around',
    1000,
    'All You Need is a WORD!',
    1000
    ]}
    wrapper="span"
    speed={50}
    style={{ fontSize: '2em', display: 'inline-block' }}
    repeat={Infinity}
  />
  </h1>
  </div>
   <button className="btn btn-1 hover-filled-slide-down">
    <span>Watch Sermons</span>
  </button>
   </section>

   <section id="about">
   <div id="about-overlay">
   <div className="container about-container mb-3">
   <h3>Welcome to</h3>
   <h1>House of Refuge</h1>
   <p className="text-xs-center">At House of Refuge, we are not merely a congregation, but citizens of a heavenly Kingdom. Here, within these walls, we seek refuge from the madness that's in the world and find solace in the presence of our King as we journey towards spiritual growth.</p>
   <p className="text-xs-center">Whether you seek solace, guidance, community, learning, or simply a moment of quiet reflection, know that you are welcomed here with open arms.</p>
   <p className="text-xs-center">Welcome, beloved, to House of Refuge, where the Kingdom of God is alive and thriving. May your time here be filled with blessings, inspiration, and a deeper understanding of our King's eternal love.</p>
   </div>
   <div>
   <center>
   <button className="btn btn-1 hover-filled-slide-down btn-lg"><span>More About us</span></button>
   <button className="btn btn-1 hover-filled-slide-down btn-lg"><span>Locate a Campus</span></button>
   <button className="btn btn-1 hover-filled-slide-down btn-lg"><span>Join RITH Cell</span></button>
   </center>
   </div>
   </div>
   </section>

  <section className="container-fluid" style={{ padding: 0, margin: 0 }}>
  <div class="card-group">
  <div class="col">
  <div className="prayer-request col-md-4">
  <h3>Request Prayer</h3>
  <button className="btn btn-1 hover-filled-slide-down btn-lg"><span>Send Request</span></button>
  </div>
  </div>

  <div class="col">
  <div className="col-md-4 testimony">
  <h3>Share a Testimony</h3>
  <button className="btn btn-1 hover-filled-slide-down btn-lg"><span>Share With us</span></button>
  </div>
  </div>

  <div class="col">
  <div className="col-md-4 tithe">
  <h3>Tithes & Offerings</h3>
  <button className="btn btn-1 hover-filled-slide-down btn-lg"><span>Give Here</span></button>
  </div>
  </div>
</div>
</section>




   <section className="container-fluid" id="events">
   <h1>Upcoming Events</h1>
   <div className="mb-3 event-column">
   <div className="col">
   <div className="p-3 col-md-4 wrapper">
  <h2 className="upper">Tue Aug</h2>
  <a href="#" className="meetup">Satisfied Women Conference</a>
  <h3 className="group text-dark">Church Auditorium</h3>
  <p className="details">
    <span className="row">
      <i className="material-icons md-36 icon">event</i>
      <span className="row-item">
      <time>Tue ANameug 2 at 5:30PM</time>
      </span>
    </span>
    <span className="row">
      <i className="material-icons md-36 icon">room</i>
      <span className="row-item">
      <strong>Yola</strong>
      <br />534 E 1st St
      </span>
    </span>
  </p>
 </div>
   </div>


   <div className="col">
   <div className="p-3 col-md-4 wrapper">
  <h2 className="upper">Tue Aug</h2>
  <a href="#" className="meetup">Jersey Sunday</a>
  <h3 className="group text-dark">Church Auditorium</h3>
  <p className="details">
    <span className="row">
      <i className="material-icons md-36 icon">event</i>
      <span className="row-item">
      <time>Tue Aug 2 at 5:30PM</time>
      </span>
    </span>
    <span className="row">
      <i className="material-icons md-36 icon">room</i>
      <span className="row-item">
      <strong>Yola</strong>
      <br />534 E 1st St
      </span>
    </span>
  </p>
 </div>
   </div>

   <div className="col">
   <div className="p-3 col-md-4 wrapper">
  <h2 className="upper">Tue Aug</h2>
  <a href="#" className="meetup">Jersey Sunday</a>
  <h3 className="group text-dark">Church Auditorium</h3>
  <p className="details">
    <span className="row">
      <i className="material-icons md-36 icon">event</i>
      <span className="row-item">
      <time>Tue Aug 2 at 5:30PM</time>
      </span>
    </span>
    <span className="row">
      <i className="material-icons md-36 icon">room</i>
      <span className="row-item">
      <strong>Yola</strong>
      <br />534 E 1st St
      </span>
    </span>
  </p>
 </div>
   </div>
 </div>
  <button className="btn btn-1 hover-filled-slide-down btn-lg"><span>See all events</span></button>
 </section>

 <section className="container-fluid" id="sermon">

 <div className="col">
 <div className="col-md-6 sermon">
 <h3 id="verse"><quote>And at that time Jesus began to preach the message of the Kingdom.</quote><br /><br />Matt IV:XVII</h3>
 <button className="btn btn-1 hover-filled-slide-down btn-lg"><span>Listen to Sermons</span></button>
 </div>
 </div>

 <div className="col audio">
 <div className="col-md-6 mb-3 audio">
 <div className="mb-4" style={{ width: '60%'}}>
 <AudioPlayer
    autoPlay={false}
    src="https://houseofrefugeglobal.org/audio/birthing.mp3"
    onPlay={e => console.log("onPlay")}
    header = {
      [
        <h3 className="text-dark">Birthing Territories</h3>
      ]
    }
  />
  </div>
 <button className="mb-4 btn btn-1 hover-filled-slide-down btn-lg"><span>Listen to Sermons</span></button>
 <br />
 </div>
 </div>

</section>

<Footer />
</div>
</div>
  )
}

export default Home
