import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from './pages/Home';

const App = () => {
  return (
    <Router>
    <Routes>
    <Route exact path="/" element={<Home />} />
    </Routes>
    </Router>
  )
}

export default App;
