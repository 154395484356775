import React from  'react';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Offcanvas from 'react-bootstrap/Offcanvas';

const  NavBarContainer = () => {
  const secondaryColor = "#2D9CDB"
  return (
    <div style={{ position: 'relative', zIndex: 3}}>
    {[false].map((expand) => (
       <Navbar key={expand} expand={expand} className="bg-body-tertiary mb-3">
         <Container fluid>
           <Navbar.Brand href="#"><strong>HOUSE OF REFUGE</strong></Navbar.Brand>
           <Navbar.Toggle aria-controls={`offcanvasNavbar-expand-${expand}`} />
           <Navbar.Offcanvas
             id={`offcanvasNavbar-expand-${expand}`}
             aria-labelledby={`offcanvasNavbarLabel-expand-${expand}`}
             placement="end"
           >
             <Offcanvas.Header closeButton>
               <Offcanvas.Title id={`offcanvasNavbarLabel-expand-${expand}`}>
                 HOR
               </Offcanvas.Title>
             </Offcanvas.Header>
             <Offcanvas.Body>
               <Nav className="justify-content-end flex-grow-1 pe-3">
                 <Nav.Link href="#action1">Home</Nav.Link>
                 <Nav.Link href="#action1">About</Nav.Link>
                 <Nav.Link href="#action2">Sermons</Nav.Link>
                 <Nav.Link href="#action2">Partnerships</Nav.Link>
                 <Nav.Link href="#action2">Events</Nav.Link>
                 <Nav.Link href="#action2">Givings</Nav.Link>
               </Nav>
             </Offcanvas.Body>
           </Navbar.Offcanvas>
         </Container>
       </Navbar>
     ))}
     </div>
  );
}

export default NavBarContainer;
