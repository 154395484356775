import React from  'react';

const Footer = () => {
  return(
    <footer class="footer footer-black">
       <div class="footer-container margin-inline">
         <p className="text-light">
           Questions? <br />
           Call 007-803-321-2130
         </p>
         <div class="links">
           <ul class="">
             <li><a class="text-terms text-underline" href="">FAQ</a></li>
             <li><a class="text-terms text-underline" href="">Cookie Preferences</a></li>
           </ul>

           <ul>
             <li><a class="text-terms text-underline" href="">Help Center</a></li>
             <li><a class="text-terms text-underline" href="">Corporate Information</a></li>
           </ul>

           <a class="text-terms text-underline" href="">Terms of Use</a>

           <a class="text-terms text-underline" href="">Privacy</a>
         </div>

         <div class="language">
           <select class="language-select" onchange="location=this.value;">
             <option value="" selected>English</option>
             <option value="">Bahasa Indonesia</option>
             <option value="">Francais</option>
           </select>
           <div class="language-icon">
             <i class="fas fa-globe globe"></i>
             <i class="fas fa-chevron-down chevron"></i>
           </div>
         </div>
       </div>
     </footer>
  )
}

export default Footer
